.App {
  width: 100;
}


.App-header {
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.App-link {
  color: #61dafb;
}
.image-container {
    position: relative;
    max-width: 100%;
}
.cooper-image {
    width: 100%;
    display: block;
}

.fade-in {
    text-align: left;
    margin: 0 2rem;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
}
.visible {
    opacity: 1;
  }

.overlay-text {
    font-size: 6vw;
    /* margin-bottom: 8px; */
    font-weight: bold;
    color: rgb(42, 42, 42);
}
  .navbar {
    background-color: black;
    color: white;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
  }

  .navbar a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
  }

  .navbar a:hover {
    color: lightgray; /* Change the color on hover */
  }

  /* .content {
    margin-top: 60px; /* Adjust margin to account for the fixed navbar height */
    /* padding: 20px;
  }  */
  
  .fix-left {
    margin-right: auto;
  }

  .fix-right {
    margin-left: auto;
  }

  .work {
    background-color: #0f1011;
    width: 100%;
    text-align: center;
    /* margin: 0 2rem; */
    color: white;
    margin-top: -30px;
    padding-top: 50px;
  }

  .text-container {
    max-width: 80%; /* Set your desired width */
    margin-left: auto; /* Optional: Align the text container to the right */
    margin-right: auto; /* Optional: Align the text container to the left */
    font-size: medium;
    text-align: left;
    margin-right: auto; 
    margin-left: 0;
  }

  .bv-img {
    border-radius: 10px;
    width: 100px;
  }

  .work-content {
    display: flex;
    margin-bottom: 50px;
    align-items: center;
    padding-right: 80px;
  }

  .move-left {
    margin: 100px;
  }

  .company-container {
    /* display: flex; */
    /* justify-content: space-between; */
    margin-bottom: 50px;
    width: 200px;
    margin-right: 50px;
    margin-left: 1rem;
  }

  .research {
    text-align: center;
    background-color: azure;
    width: 100%;
    color: rgb(36, 29, 29);
    padding-top: 1rem;
    margin-bottom: 0px;
    margin-top: -50px;
  }

  .research-link {
    color: rgb(36, 29, 29);
    text-decoration: none; 
  }

  .contact {
    background-color: white;
    width: 100%;
    align-items: center;
  }

  .contact-img {
    width: 50px;
    margin-right: 1rem;
  }

  .contact-content {
    display: flex;
    margin-bottom: 1rem;
    margin-right: 1rem;
    align-items: left;
    text-align: center;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-img {
    margin: 20px; /* Adjust the value as needed */
  }